
@keyframes shake {
  0%,
  100% { transform: translate3d(0, 0, 0); }

  15% { transform: translate3d(-10px, 0, 0); }

  32% { transform: translate3d(8px, 0, 0); }

  44% { transform: translate3d(-6px, 0, 0); }

  70% { transform: translate3d(4px, 0, 0); }
}
