// Settings
$cdn-host: "//cdn.outdoorvoices.com/shopify/2.0.0/";

// Colors
$color-active: #ccd3da;
$color-black: #000;
$color-charcoal: #202020;
$color-gray-light: #d2d2d2;
$color-gray-xlight: #f1f1f1;
$color-gray-xlight-alpha: rgba(32, 32, 32, 0.07);
$color-gray-dark: #737373;
$color-ov-blue: #000f9f;
$color-red: #dd000d;
$color-white: #fff;

$color-disabled: $color-gray-light;
$color-hover: $color-ov-blue;

// Fonts
$font-family-serif: "merlo", georgia, serif;
$font-family-serif-italic: "merlo-italic", georgia, serif;
$font-family-sans-serif: "ov-gothic", "HelveticaNeue-Bold", "Helvetica Neue Bold", "Helvetica Neue", arial, sans-serif;

$font-size-xxl: 48px;
$font-size-xl: 36px;
$font-size-lg: 24px;
$font-size-md: 18px;
$font-size-smd: 17px;
$font-size-sm: 16px;
$font-size-xsm: 14px;

// Dimensions
$max-width: 1656px;
$nav-height: 72px;
$banner-height: 36px;
$footer-height: 108px;

// OLD Breakpoints
$break-medium: 1024px;
$break-small: 780px;
$break-xsmall: 480px;

// Breakpoints
$break-mobile-large: 767px;
$break-mobile-medium-2: 519px;
$break-mobile-medium: 666px;
$break-mobile-small: 380px;
$break-mobile-xsmall: 340px;
$break-mobile: 480px;
$break-standard-screen: 1024px;
$break-super-huge: $max-width;
$break-external-display-huge: 2000px;
$break-tablet-medium: 940px;
$break-tablet-small: 890px;
$break-tablet: 1200px;

// Column Breakpoints
$break-three-column: 1420px;
$break-two-column: 960px;
$break-one-column: 550px;

// Easing functions
$ease-in-out: ease-in-out;
$ease-default: ease-in-out;
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
$ease-timing-fastest: 0.08s;
$ease-timing-fast: 0.1s;
$ease-timing-medium: 0.15s;
$ease-timing-slow: 0.2s;
$ease-timing-slowest: 0.8s;

// animation timings
$arrowAnimationTime: $ease-timing-slowest;
$arrowAnimationFunc: $ease-out-quart;

$z-header: 5;
$z-filters: 4;
$z-alert: 10;
$z-alert-bg: 9;
$z-skip: 20;
