@mixin ada-hidden {
  background-color: $color-white;
  border: 0;
  box-sizing: border-box;
  clip: rect(1px, 1px, 1px, 1px);
  clip: rect(1px 1px 1px 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  text-align: center;
  top: 0;
  width: 1px;
}

@mixin label {
  cursor: pointer;
  display: inline-block;
  font-size: $font-size-sm;
  margin-bottom: 12px;
}

// Triangles
// http://apps.eky.hk/css-triangle-generator/
@mixin triangle {
  border-style: solid;
  content: ' ';
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}

// CDN Font-Face
// -------------------------
@mixin cdn-font-face($font-family, $file-name, $font-style, $font-weight) {
  @font-face {
    font-display: swap;
    font-family: $font-family;
    font-style: $font-style;
    font-weight: $font-weight;
    src: url('#{$cdn-host}fonts/#{$file-name}.eot');
    src:
      url('#{$cdn-host}fonts/#{$file-name}.eot?#iefix') format('embedded-opentype'),
      url('#{$cdn-host}fonts/#{$file-name}.woff2') format('woff2'),
      url('#{$cdn-host}fonts/#{$file-name}.woff') format('woff'),
      url('#{$cdn-host}fonts/#{$file-name}.ttf') format('truetype'),
      url('#{$cdn-host}fonts/#{$file-name}.svg##{$file-name}') format('svg');
  }
}

@mixin match-grid {
  margin-left: auto;
  margin-right: auto;
  max-width: $max-width;
  padding-left: 108px;
  padding-right: 108px;

  @media (max-width: $break-three-column) {
    padding-left: 78px;
    padding-right: 78px;
  }

  @media (max-width: $break-two-column) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (max-width: $break-one-column) {
    padding-left: 12px;
    padding-right: 12px;
  }
}
