@import '../../scss/config';
@import '../../scss/mixins';

.root {
  display: block;
}

.label {
  font-size: $font-size-xsm;
  line-height: 1;
  margin-bottom: 12px;

  &.adaHidden {
    @include ada-hidden;
  }
}

.disabled {
  cursor: default;
}

.placeholder {
  color: $color-charcoal;
  height: 100%;
  left: 0;
  padding: 12px;
  position: absolute;
  top: 0;
  z-index: 1;
}

.button {
  width: 100%;
}

.buttonLabel {
  align-items: center;
  display: flex;

  &.withoutChevron {
    min-height: 36px;
    min-width: 36px;
  }
}

.icon {
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  opacity: .2;
  pointer-events: none;
  position: absolute;
  right: -1px;
  top: -1px;
  width: 48px;
}

.options {
  background: $color-white;
  border-radius: 8px;
  box-shadow: 0 6px 24px 6px rgba($color-charcoal, .1);
  margin-top: -24px;
  max-height: 280px;
  opacity: 0;
  overflow: auto;
  padding: 13px 0;
  position: fixed;
  transition-duration: $ease-timing-slow;
  transition-property: opacity, visibility, margin-top;
  transition-timing-function: $ease-in-out-quart;
  visibility: hidden;
  z-index: 9999;

  &.on {
    margin-top: 0;
    opacity: 1;
    visibility: visible;
  }
}

.optionsList {
  &:focus {
    outline: none;
  }
}

.optionButton {
  align-items: center;
  background: none;
  border: 0;
  border-radius: 0;
  color: $color-charcoal;
  display: flex;
  flex: 1;
  font-family: $font-family-sans-serif;
  font-size: $font-size-sm;
  height: 36px;
  justify-content: space-between;
  padding: 0 36px 0 24px;
  position: relative;
  text-align: left;
  transition: background $ease-timing-fast $ease-in-out;
  width: 100%;

  &:hover {
    background: $color-gray-xlight;
    color: $color-charcoal;
  }

  &:focus {
    color: $color-charcoal;
  }
}

.optionIcon {
  fill: $color-ov-blue;
  position: absolute;
  right: 0;
  top: 0;
}

.select {
  appearance: none;
  background: none;
  border: 0;
  display: none;
  height: 100%;
  overflow: hidden;
  padding-left: 12px;
  padding-right: 48px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  z-index: 2;
}

@media (max-width: $break-mobile) {
  .root {
    &::before {
      content: none;
    }
  }

  .options {
    left: 24px !important;
    max-height: calc(100vh - 48px);
    overflow-y: auto;
    top: 50% !important;
    transform: translateY(-50%);
    width: calc(100vw - 48px);
  }

  .option {
    border-bottom: 1px solid $color-gray-xlight;
    height: 46px;

    &:last-child {
      border-bottom: 0;
    }
  }
}
