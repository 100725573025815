@import '../../scss/config';

.root {
  background: $color-charcoal;
  border: 0;
  border-radius: 100px;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-family: $font-family-sans-serif;
  font-size: $font-size-sm;
  line-height: 36px;
  padding: 0 24px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background .2s $ease-default, color .2s $ease-default;
  white-space: nowrap;

  &:hover {
    background: $color-ov-blue;
    color: $color-white;
    cursor: pointer;
  }

  &:active {
    position: relative;
    top: 1px;
  }

  &:focus {
    color: $color-white;
  }

  svg {
    fill: currentColor;
  }

  span {
    text-align: center;
  }
}

.alert {
  background: $color-red;
}

.big {
  border-radius: 24px;
  height: 48px;
  line-height: 48px;
}

.bordered {
  background: transparent;
  border: 2px solid $color-charcoal;
  color: $color-charcoal;
  line-height: 34px;

  &:focus {
    color: $color-charcoal;
  }

  &:hover {
    background: $color-charcoal;
    color: $color-white;
  }

  &.big {
    line-height: 44px;
  }
}

.full {
  width: 100%;
}

.medium {
  width: 249px;
}

.small {
  font-size: $font-size-xsm;
}

.disabled {
  &,
  &:hover {
    background: $color-gray-light;
    cursor: default;
  }

  svg {
    fill: $color-gray-light;
  }
}

.linkStyle {
  background: transparent;
  border-radius: 0;
  color: $color-charcoal;
  display: inline-flex;
  height: auto;
  line-height: inherit;
  padding: 0;

  &:focus {
    color: $color-charcoal;
  }

  &:hover {
    background: transparent;
    color: $color-ov-blue;
  }
}

.gray {
  background: $color-gray-xlight;
  color: $color-charcoal;

  &:focus {
    color: $color-charcoal;
  }

  &:hover {
    background: $color-gray-light;
    color: $color-charcoal;
  }

  &.on {
    background: $color-active;
  }
}

.white {
  background: $color-white;
  color: $color-charcoal;

  &:focus,
  &:active,
  &:hover {
    background: $color-charcoal;
    color: $color-white;
  }
}

.borderedHover {
  background: $color-white;
  border: 2px solid transparent;
  color: $color-charcoal;

  &:hover {
    background: $color-charcoal;
    border-color: $color-white;
    color: $color-white;
  }
}

.borderedInverseHover {
  background: transparent;
  border: 2px solid $color-white;
  color: $color-white;

  &:active,
  &:focus,
  &:hover {
    background: $color-white;
    color: $color-charcoal;
  }
}

.iconLeft {
  padding-left: 36px;
  text-align: right;

  &.small {
    padding-left: 25px;
  }

  svg {
    left: 0;
  }
}

.iconRight {
  padding-right: 36px;
  text-align: left;

  &.small {
    padding-right: 25px;
  }

  svg {
    right: 0;
  }
}

.iconLeft,
.iconRight {
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &.small svg {
    height: 25px;
    width: 25px;
  }
}
