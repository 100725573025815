.circle {
  border-radius: 50%;
}

.roundedTop {
  border-radius: 50% 50% 0 0;
}

.roundedBottom {
  border-radius: 0 0 50% 50%;
}
