@import '../../scss/config';

.root {
  animation: rotate 1.5s linear infinite;
  height: 100%;
  margin: 0 auto;
  max-width: 120px;
  transform-origin: center center;
  width: 100%;
}

.circle {
  animation: dash 4s ease infinite;
  stroke: $color-gray-xlight;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;

  &.white {
    stroke: $color-white;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
